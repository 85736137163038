// --------------------------------------------------
// APP.JS
// --------------------------------------------------

//
// Initialize Foundation
// --------------------------------------------------

$(document).foundation();
Foundation.Abide.defaults.patterns['phone_number'] = /^[0-9\(\). +-]+$/;
// Foundation.Reveal.dataAnimationIn = 'fade-in';

// var m = 'asopecam';
// m += '2000';
// m += '@';
// m += 'yahoo.com';

// http://stackoverflow.com/questions/5004233/jquery-ajax-post-example-with-php
// Variable to hold request
var request;

// http://foundation.zurb.com/forum/posts/37358-foundation-6-and-abide
// to submit via ajax, add the 2 bindings below.

// https://get.foundation/sites/docs/abide.html#event-listener
$("#contact-form")
  // field element is invalid
  .on("invalid.zf.abide", function(ev,elem) {
    console.log("Field id "+ev.target.id+" is invalid");
  })
  // field element is valid
  .on("valid.zf.abide", function(ev,elem) {
    console.log("Field name "+elem.attr('name')+" is valid");
  })
  // form validation failed
  .on("forminvalid.zf.abide", function(ev,frm) {
    console.log("Form id "+ev.target.id+" is invalid");
  })
  // form validation passed, form will submit if submit event not returned false
  .on("formvalid.zf.abide", function(ev,frm) {
    console.log("Form id "+frm.attr('id')+" is valid");

    // ajax post form

    // Abort any pending request
    if (request) {
      request.abort();
    }

    // setup some local variables
    var $form = $(this);
    // var $form = $("#contact-form");
    var $modalError = $('#contact-form-error');
    var $modalSuccess = $('#contact-form-success');

    // Let's select and cache all the fields
    var $inputs = $form.find("input, select, button, textarea");

    // Serialize the data in the form
    var serializedData = $form.serialize();

    // Let's disable the inputs for the duration of the Ajax request.
    // Note: we disable elements AFTER the form data has been serialized.
    // Disabled form elements will not be serialized.
    $inputs.prop("disabled", true);

    // Fire off the request to /mail.php
    request = $.ajax({
        url: "/mail.php",
        type: "post",
        data: serializedData,
        success: function(data) { 
          // https://stackoverflow.com/questions/37187699/ajax-error-is-returned-as-success
          if (data.success) {  //as you are passing true/false from server side.
            // Log a message to the console
            console.log("Hooray, it worked!");
            $modalSuccess.foundation('open');
          } else {
            // Log the error to the console
            console.error(
                "The following error occurred: "+
                data.error
            );
            $modalError.foundation('open');
          }
        },        
    });

    // // Callback handler that will be called on success
    // request.done(function (response, textStatus, jqXHR){
    //     // Log a message to the console
    //     console.log("Hooray, it worked!");
    //     $modalSuccess.foundation('open');
    // });

    // Callback handler that will be called on failure
    request.fail(function (jqXHR, textStatus, errorThrown){
        // Log the error to the console
        console.error(
            "The following error occurred: "+
            textStatus, errorThrown
        );
        $modalError.foundation('open');
    });

    // Callback handler that will be called regardless
    // if the request failed or succeeded
    request.always(function () {
        // Reenable the inputs
        $inputs.prop("disabled", false);
    });


  })
  // to prevent form from submitting upon successful validation
  .on("submit", function(ev) {
    ev.preventDefault();
    console.log("Submit for form id "+ev.target.id+" intercepted");
  });

// // You can bind field or form event selectively
// $("#foo").on("invalid.zf.abide", function(ev,el) {
//   alert("Input field foo is invalid");
// });

// $("#bar").on("formvalid.zf.abide", function(ev,frm) {
//   alert("Form is valid, finally!");
//   // do something perhaps
// });


//
// Custom JS
// --------------------------------------------------

// https://stackoverflow.com/a/33790988
function scrollToAnchor(name) {
  // change location
  window.location = '#' + name;

  // scroll to element anyway
  var element = $("[name='" + name + "']")[0];
  if (!element) {
    console.error("No such anchor with name", name);
    return;
  }
  element.scrollIntoView();

  // scroll back to leave room for sticky nav
  var stickyNavHeight = $("nav.top-bar").height();
  console.log(stickyNavHeight);
  window.scrollTo(window.scrollX, window.scrollY - stickyNavHeight);
}
